<template>
    <form class="space-y-8 divide-y divide-gray-200" @submit.prevent="SaveUserData">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <!-- <div class="space-y-6 sm:space-y-5">
                        <div>
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Profile</h3>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">This information will be displayed publicly so be careful what you share.</p>
                        </div>
        
                        <div class="space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Username</label>
                                <div class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="flex max-w-lg rounded-md shadow-sm">
                                        <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">workcation.com/</span>
                                        <input type="text" name="username" id="username" autocomplete="username" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm" />
                                    </div>
                                </div>
                            </div>
        
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">About</label>
                                <div class="mt-1 sm:col-span-2 sm:mt-0">
                                    <textarea id="about" name="about" rows="3" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm" />
                                    <p class="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
                                </div>
                            </div>
        
                            <div class="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="photo" class="block text-sm font-medium text-gray-700">Photo</label>
                                <div class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="flex items-center">
                                        <span class="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                                            <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                        <button type="button" class="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Change</button>
                                    </div>
                                </div>
                            </div>
        
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Cover photo</label>
                                <div class="mt-1 sm:col-span-2 sm:mt-0">
                                    <div class="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                        <div class="space-y-1 text-center">
                                            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <div class="flex text-sm text-gray-600">
                                                <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-medium text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:text-primary-500">
                                                    <span>Upload a file</span>
                                                    <input id="file-upload" name="file-upload" type="file" class="sr-only" />
                                                </label>
                                                <p class="pl-1">or drag and drop</p>
                                            </div>
                                            <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

            <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Persönliche Informationen</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Bitte nutzen Sie eine Adresse, mit der Sie täglich Mails abrufen</p>
                </div>
                <div class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="firstname" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Vorname</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="firstname" id="firstname" required v-model="state.formData.user_firstname" :disabled="disabled" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nachname</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="last-name" id="last-name" required :disabled="disabled" v-model="state.formData.user_lastname" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">E-mail</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input id="user_email" name="user_email" type="email" required class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm disabled:bg-gray-100" :disabled="disabled" v-model="state.formData.user_email" />
                        </div>
                    </div>

                    <!-- <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Country</label>
                                <div class="mt-1 sm:col-span-2 sm:mt-0">
                                    <select id="country" name="country" autocomplete="country-name" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm">
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>Mexico</option>
                                    </select>
                                </div>
                            </div> -->

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_street" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Strasse</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="user_street" id="user_street" v-model="state.formData.user_street" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_plz" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">PLZ</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="user_plz" id="user_plz" autocomplete="user_plz" v-model="state.formData.user_plz" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_city" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Stadt</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="user_city" id="user_city" v-model="state.formData.user_city" autocomplete="address-level2" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>


                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_land" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Land</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">

                            <!-- <input type="text" name="user_land" id="user_land" autocomplete="address-level1" v-model="state.formData.user_land" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" /> -->
                            <select id="user_land" name="user_land" autocomplete="user_land" v-model="state.formData.user_land" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm" :disabled="disabled">
                                <option value="Schweiz">Schweiz</option>
                                <option value="Deutschland">Deutschland</option>
                                <option value="Österreich">Österreich</option>
                            </select>
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_mobile" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Telefon</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="tel" name="user_mobile" id="user_mobile" autocomplete="user_mobile" v-model="state.formData.user_mobile" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_mobile" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Avatar</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <div class="col-span-full">
                                <label for="photo" class="block text-sm font-medium leading-6 text-gray-900"></label>
                                <div class="mt-2 flex items-center gap-x-3">
                                    
                                    <img class="inline-block h-14 w-14 rounded-full" :src="user_avatar" v-if="user_avatar" alt="" />

                                    <UserCircleIcon class="h-14 w-14 text-gray-300" aria-hidden="true" v-else />
                                    <FileUpload mode="basic" :customUpload="true" @uploader="upload_avatar_image" name="user_avatar" :multiple="false" accept="image/*" :maxFileSize="10000000" @upload="onUpload" v-if="userdata.user_id" choose-label="Datei auswählen" />
                                    <div class="text-red-600 w-2/3" v-else> Upload zur Zeit nicht möglich, bitte speichern Sie ihr Profil und loggen sich erneut ein</div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <!-- <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Abbruch</button> -->
                <button type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-100">
                    <LoadingSpinner size="4" color="fill-primary-600" v-if="state.spinner" background-dark="dark:text-gray-100"></LoadingSpinner> Speichern
                </button>
            </div>
        </div>
    </form>
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10">
        <MainToast :mode="state.message.messagemode" v-if="state.message.show">{{ state.message.message }}</MainToast>
    </div>
</template>
<script setup>
import { onBeforeMount, ref, inject, reactive } from 'vue';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import MainToast from '@/core/components/UI/Main-Toast.vue';
import { GetUserData } from '@/core/var/user';
import { Message, ErrorLog } from '@/core/var/Message'
import Simplestore from '@/core/var/Simplestore';
import axios from 'axios';
import { useAuthStore } from '@/core/store/AuthStore';
import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import { useCustomerStore } from '@/core/store/CustomerStore';
import { getRandomId } from '@/core/var/tools';
import Image from 'primevue/image';

const auth = useAuthStore();
const disabled = ref(true);
const dialog_uploadavatar = ref(false);
const channel = ref(useCustomerStore().getCustomer.customer)
const state = reactive({
    formData: { user_land: "Schweiz" },
    formDataPW: {},
    spinner: false,
    servererror: false,
    message: {
        show: false,
        message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
        messagemode: 'error'
    },
});
const user_avatar = ref(false);
const user_channel = ref(process.env.VUE_APP_NEURAXIS_CHANNEL_ID)
const userdata = auth.getUserData;

const upload_avatar_image = async (res) => {
    const reader = new FileReader();
    reader.readAsDataURL(res.files[0]);
    reader.onload = async () => {

        const payload = {
            filename: res.files[0].name,
            file: reader.result,
            user_id: userdata.user_id,
            image_type: 'avatar',
            channel: channel.value,
            user_email: userdata.user_email
        };

        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/upload-avatar`,
            payload
        );
        user_avatar.value = response.data.image_url;
    };
    await new Promise(resolve => setTimeout(resolve, 2000));
}

const onUpload = () => {
    toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};
onBeforeMount(async () => {
    Simplestore.spinner = true;
    GetUserData({
        user_id: userdata.user_id,
        user_email: userdata.user_email
    }).then(res => {
        disabled.value = false;
        state.formData = res.data;
        user_avatar.value = res.data.user_avatar;
        Simplestore.spinner = false;
    });
});

const SaveUserData = async () => {

    const user_id = userdata.user_id == true ? userdata.user_id : getRandomId(6)
    const avatar = user_avatar.value
    const payload =
    {
        "user_id": user_id,
        "user_email": state.formData.user_email,
        "user_firstname": state.formData.user_firstname,
        "user_lastname": state.formData.user_lastname,
        "user_mobile": state.formData.user_mobile,
        "user_active": true,
        "user_channel": user_channel.value,
        "user_roles": [auth.getUserRole],
        "user_street": state.formData.user_street,
        "user_land": state.formData.user_land,
        "user_city": state.formData.user_city,
        "user_plz": state.formData.user_plz,
        "user_avatar": avatar
    }

    if (payload.user_email) {

        state.spinner = true;
        disabled.value = true;
        Simplestore.spinner = true;
        await axios
            .post("/dashboard/edit-user", payload)
            .then((response) => {
                //console.log(response.data);
                if (response.data === false) {
                    Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                    state.spinner = false;
                    Simplestore.spinner = false;
                    disabled.value = false;
                } else {

                    if (response.data.message && response.data.message === 'error') {
                        Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    } else {
                        //console.log(response.data);
                        localStorage.setItem(process.env.VUE_APP_NEURAXIS_TOKENNAME, response.data.jwt);
                        state.spinner = false;
                        disabled.value = false;
                        state.message.messagemode = 'success';
                        state.message.message = 'Ihre Daten wurden erfolgreich gespeichert';
                        state.message.show = true;
                        Simplestore.spinner = false;
                        //console.log(state.message.message);
                        window.setTimeout(() => {
                            state.message.show = false;
                        }, 3000)
                    }
                }

            })
            .catch(function (error) {
                state.message.show = true;
                ErrorLog(error);
                window.setTimeout(() => {
                    state.spinner = false
                    state.message.show = false;
                    disabled.value = false;
                    Simplestore.spinner = false;
                }, 3000)


            })
    }
}
</script>