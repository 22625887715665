<template>
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button v-if="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
            <Button :disabled="changes != 0 ? false : true" @click="save_changes()" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
            <Divider layout="vertical" />
            <Button @click="newTeamDialog = true, team_name = '', team_role = '', team_email = '', team_phone = ''" label="Mitarbeiter hinzufügen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Mitarbeiter erfassen'" icon="pi pi-plus" />
            <Button @click="imageUploadDialog = true" label="Bilder hochladen" class="w-auto mr-2 p-button" v-tooltip.top="'Bilder in die Mitarbeiter Bibliothek hochladen'" icon="pi pi-upload" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_team['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_team" :value="team" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onTeamRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="status" header="Status" style="width: 100px">
            <template #body="slotProps">
                <Chip v-if="slotProps.data.status == 'draft'" :label="get_status(slotProps.data.status)" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #d32f2f; color: #FFF" />
                <Chip v-if="slotProps.data.status == 'published'" :label="get_status(slotProps.data.status)" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #689f38; color: #FFF" />
            </template>
        </Column>
        <Column field="image" style="width: 100px">
            <template #body="slotProps">
                <img :src="slotProps.data.avatar" width="64" class="shadow-2 mr-2" style="vertical-align: middle; width: 64px; height: 64px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
            </template>
        </Column>
        <Column field="name" header="Namen">
            <template #body="slotProps">
                <span class="font-bold">{{ slotProps.data.name }}</span>
            </template>
        </Column>
        <Column field="role" header="Rolle">
            <template #body="slotProps">
                <span>{{ slotProps.data.role }}</span>
            </template>
        </Column>
        <Column field="email" header="E-Mail">
            <template #body="slotProps">
                <span>{{ slotProps.data.email }}</span>
            </template>
        </Column>
        <Column field="phone" header="Tel-Nr.">
            <template #body="slotProps">
                <span>{{ slotProps.data.phone }}</span>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false"
                    @click="editTeamDialog = true, edit_image = false, edit_avatar = false, team_name = slotProps.data.name, team_role = slotProps.data.role, team_email = slotProps.data.email, team_phone = slotProps.data.phone, team_image = slotProps.data.image, team_index = slotProps.index, team_status = slotProps.data.status, team_external = slotProps.data.external, team_content = slotProps.data.content, team_avatar = slotProps.data.avatar, team_workflow_id = slotProps.data.workflow_id"
                    class="p-button-rounded p-button-text" v-tooltip.top="'Mitarbeiter bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.workflow_id)" class="p-button-rounded p-button-text" v-tooltip.top="'Mitarbeiter löschen'" icon="pi pi-trash" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------ Edit Team ---------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTeamDialog" :style="{ width: '80%' }" header="Mitarbeiter bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <!-- <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <img :src="team_image" class="shadow-2" style="width: 400px;" />
            </div> -->
            <!-- <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <img :src="team_avatar" class="shadow-2" style="width: 300px;" />
            </div> -->
            <!-- <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <Button v-if="edit_image == false" label="Bild Bearbeiten" @click="edit_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            </div> -->
            <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <Button v-if="edit_image == false" label="Avatar Bearbeiten" @click="edit_avatar = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            </div>
            <!-- <div v-if="edit_image == true" class="field col-12 mt-3">
                <cropper ref="croppy" :src="team_image" :auto-zoom="true" :auto-center="true" :stencil-props="{ aspectRatio: 4 / 3 }" />
            </div> -->
            <div v-if="edit_avatar == true" class="field col-12 mt-3">
                <cropper ref="croppy_avatar" :src="team_image" :auto-zoom="true" :auto-center="true" :stencil-props="{ aspectRatio: 1 / 1 }" />
            </div>

            <div v-if="edit_image == true || edit_avatar == true" class="field col-12 mt-3">
                <template v-for="(img, index) of image_library" :key="index">
                    <img @click="team_image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; height: 100px; width: 100px; object-fit: cover; display:inline;" />
                </template>
            </div>
            <!-- <div v-if="edit_image == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_image()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div> -->
            <div v-if="edit_avatar == true" class="field col-12 mt-3">
                <Button label="Avatar Speichern" @click="crop_avatar()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>

            <div class="field col-6 mt-1">
                <label for="name">Name</label>
                <InputText v-model="team_name" id="name" />
            </div>
            <div class="field col-6 mt-1">
                <label for="role">Rolle</label>
                <InputText v-model="team_role" id="role" />
            </div>
            <div class="field col-6 mt-1">
                <label for="email">E-Mail</label>
                <InputText v-model="team_email" id="email" />
            </div>
            <div class="field col-6 mt-1">
                <label for="phone">Tel-Nr.</label>
                <InputText v-model="team_phone" id="phone" />
            </div>
            <div class="field col-6 mt-1">
                <label for="status">Status</label>
                <Dropdown v-model="team_status" id="status" :options="status" optionLabel="label" optionValue="value" />
            </div>
            <div class="field col-6 mt-1">
                <label for="external">Extern</label>
                <Dropdown v-model="team_external" id="external" :options="external" optionLabel="label" optionValue="value" />
            </div>
            <div class="field col-12 mt-1">
                <label for="content">Beschreibung</label>
                <Editor editorStyle="width: 100%; min-height: 200px;" v-model="team_content" id="content">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-12 mt-1">
                <Button label="Speichern" @click="save_team(team_index), editTeamDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editTeamDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------ New Team ---------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="newTeamDialog" :style="{ width: '80%' }" header="Mitarbeiter hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-1">
                <label for="name">Name</label>
                <InputText v-model="team_name" id="name" />
            </div>
            <div class="field col-6 mt-1">
                <label for="role">Rolle</label>
                <InputText v-model="team_role" id="role" />
            </div>
            <div class="field col-6 mt-1">
                <label for="email">E-Mail</label>
                <InputText v-model="team_email" id="email" />
            </div>
            <div class="field col-6 mt-1">
                <label for="phone">Tel-Nr.</label>
                <InputText v-model="team_phone" id="phone" />
            </div>
            <div class="field col-12 mt-1">
                <Button :disabled="team_name == ''" label="Speichern" @click="new_team(), newTeamDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newTeamDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!---------------- Image Library -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="imageUploadDialog" :style="{ width: '800px' }" header="Bilder hochladen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div>
                            <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                            <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>
</template>

<style lang="scss" scoped>
@import '@/core/assets/primevue/primeflex.scss';
</style>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import { useCustomerStore } from '@/core/store/CustomerStore'
import 'vue-advanced-cropper/dist/style.css';

import { getRandomId, getCurrentDate, tags2array } from '@/core/var/tools'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const team = ref([])
const team_name = ref(null)
const team_role = ref(null)
const team_status = ref(null)
const team_email = ref(null)
const team_phone = ref(null)
const team_image = ref(null)
const team_avatar = ref(null)
const team_external = ref(null)
const team_content = ref(null)
const team_workflow_id = ref(null)

const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editTeamDialog = ref(false)
const newTeamDialog = ref(false)
const imageUploadDialog = ref(false)
const image_library = ref([])
const edit_image = ref(false)
const edit_avatar = ref(false)
const force_save = ref(false)
const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])
const external = ref([
    { label: 'Nein', value: false },
    { label: 'Ja', value: true }
])
const croppy = ref(null)
const croppy_avatar = ref(null)
const filter_team = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })


const slug = ref(useCustomerStore().getCustomer.customer);



onMounted(async () => {
    try {
        const [teamResponse, imageLibraryResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/get-team`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/get-team-image-library`)
        ]);
        team.value = teamResponse.data;
        image_library.value = imageLibraryResponse.data;
        console.log(image_library.value);
    } catch (error) {
        console.error(error);
    }
});
const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/get-team`);
        team.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onTeamRowReorder = (e) => {
    team.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    for (let i = 0; i < status.value.length; i++) {
        if (status.value[i].value == status_value) {
            if (status_value == "published") {
                return "Aktiv"
            }
            if (status_value == "draft") {
                return "Inaktiv"
            }
        }
    }
}

// const crop_image = () => {
//     team_image.value = croppy.value.getResult().canvas.toDataURL()
//     upload_image()
// }

const crop_avatar = () => {
    team_avatar.value = croppy_avatar.value.getResult().canvas.toDataURL()
    upload_avatar()
}


// const upload_image = async () => {
//     try {
//         const { canvas } = croppy.value.getResult();
//         await canvas.toBlob(async (blob) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(blob);
//             reader.onload = async () => {
//                 const payload = {
//                     file: reader.result,
//                     image_type: "image",
//                     workflow_id: team_workflow_id.value
//                 };
//                 const response = await axios.post(
//                     `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/upload-team-image`,
//                     payload
//                 );
//                 team_image.value = response.data.image_url;
//             };
//         });
//     } catch (error) {
//         console.error(error);
//     }
// };

const upload_avatar = async () => {
    try {
        const { canvas } = croppy_avatar.value.getResult();
        await canvas.toBlob(async (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = async () => {
                const payload = {
                    file: reader.result,
                    image_type: "avatar",
                    workflow_id: team_workflow_id.value
                };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/upload-image`,
                    payload
                );
                team_avatar.value = response.data.image_url;
            };
        });
    } catch (error) {
        console.error(error);
    }
};


const upload_library_image = async (e) => {
    try {
        for (let i = 0; i < e.files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(e.files[i]);
            reader.onload = async () => {
                const payload = { filename: e.files[i].name, file: reader.result };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/upload-team-library-image`,
                    payload
                );
                image_library.value = response.data;
                toast.add({
                    severity: "success",
                    summary: "Erfolg",
                    detail: `Bild ${e.files[i].name} erfolgreich hochgeladen`,
                    life: 3000
                });
            };

            await new Promise(resolve => setTimeout(resolve, 2000));
        }
    } catch (error) {
        console.error(error);
    }
};


const save_team = (index) => {
    team.value[index]["workflow_id"] = getRandomId(6);
    team.value[index]["name"] = team_name.value
    team.value[index]["role"] = team_role.value
    team.value[index]["status"] = team_status.value
    team.value[index]["email"] = team_email.value
    team.value[index]["phone"] = team_phone.value
    team.value[index]["image"] = team_image.value
    team.value[index]["avatar"] = team_avatar.value
    team.value[index]["external"] = team_external.value
    team.value[index]["content"] = team_content.value
    incrementSaves();
}

const new_team = () => {
    team.value.unshift({
        "workflow_id": getRandomId(6),
        "name": team_name.value,
        "role": team_role.value,
        "status": "draft",
        "email": team_email.value,
        "phone": team_phone.value,
        "image": "",
        "avatar": "",
        "external": false,
        "content": ""
    })
    force_save.value = true
    incrementSaves();
}

const delete_dataset = (id) => {
    const newt = team.value.filter(dataset => dataset.workflow_id !== id);
    team.value = newt;
    incrementSaves();
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {
    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/save-team`,
            team.value
        );
        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

</script>
