<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" class="h-12 w-12 z-50 absolute" strokeWidth="5" animationDuration="1.5s" aria-label="ProgressSpinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
            <TabPanel>
                <template #header>
                    <i class="pi pi-comment mr-2"></i>
                    <span class="mr-3">{{ text.head }}</span>
                </template>
                <Toolbar>
                    <template #start>
                        <Button v-if="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                        <Button :disabled="changes != 0 ? false : true" @click="save_changes()" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
                        <Button @click="newBlogDialog = true, new_blog_title = '', new_blog_category = ''" :label="text.new" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Magazin Beitrag erstellen'" icon="pi pi-plus" />
                    </template>
                    <template #end>
                        <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" />
                    </template>
                </Toolbar>
                <Toolbar class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_blogs['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                        </span>
                        <Dropdown :showClear="true" @change="select_status_filter()" placeholder="Status" id="status_filter" v-model="filter_blog_status" :options="blog_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        <Dropdown :filter="true" :showClear="true" @change="select_category_filter()" placeholder="Kategorie" id="category_filter" v-model="filter_blog_category" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                    </template>
                </Toolbar>
                <!--------------------------------------------------->
                <!------------------ DataTable ---------------------->
                <!--------------------------------------------------->
                <DataTable class="mt-3" v-model:filters="filter_blogs" :value="blogs" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" editMode="cell" @cell-edit-complete="onBlogRowEdit" @rowReorder="onTextFieldRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="status" header="Status" style="width: 100px">
                        <template #editor="{ data, field }">
                            <Dropdown id="blog_status" v-model="data[field]" :options="blog_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1 bg-red-400 text-white" />
                            <Chip v-if="data[field] == 'published'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1 bg-green-600 text-white" />
                        </template>
                    </Column>
                    <Column field="header_image" style="width: 130px">
                        <template #body="slotProps">
                            <img v-if="slotProps.data.header_image" :src="slotProps.data.header_image" width="32" class="shadow-2 mr-2 align-middle w-32 h-16 object-cover rounded-md shadow-sm" />
                            <ImagePlaceholder class="h-16 w-16" v-else />
                        </template>
                    </Column>
                    <Column field="title" header="Titel">
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <!-- <Column field="category" header="Thema" style="width: 130px">
                        <template #editor="{ data, field }">
                            <Dropdown id="blog_category" v-model="data[field]" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip :label="get_category(data[field])" v-tooltip.top="'Kategorie / Thema'" icon="pi pi-comment" class="mr-1 bg-yellow-500 text-white" />
                        </template>
                    </Column> -->
                    <Column field="dm" header="Modifikation" style="width: 180px" sortable>
                        <template #body="slotProps">
                            <span>{{ swiss_date_time(slotProps.data.dm) }}</span>
                        </template>
                    </Column>
                    <Column field="user" header="Bearbeiter" style="width: 350px">
                        <template #editor="{ data, field }">
                            <Dropdown :showClear="true" id="user" v-model="data[field]" :options="users" optionLabel="user_fullname" optionValue="user_email" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip :label="data[field]" v-tooltip.top="'Der aktuellen Bearbeiter vom Blog'" icon="pi pi-user" class="mr-1 text-white bg-primary-600" />
                        </template>
                    </Column>
                    <Column style="width: 250px;">
                        <template #body="slotProps">
                            <!-- <Button v-if="slotProps.data.workflow_id" @click="preview_dataset(slotProps.data.slug, SERVICE_URL, MODULE)" v-tooltip.top="'Artikel Vorschau'" class="p-button-rounded sm p-button-text" icon="pi pi-eye" /> -->
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="edit_blog(slotProps.data.redis_key)" v-tooltip.top="'Artikel editieren'" class="p-button-rounded p-button-text" icon="pi pi-pencil" />
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="copy_blog(slotProps.data.redis_key)" v-tooltip.top="'Artikel kopieren'" class="p-button-rounded p-button-text" icon="pi pi-copy" />
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="delete_blog(slotProps.data.redis_key)" v-tooltip.top="'Artikel löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
            <TabPanel v-for="(editor, key) in editing" :key="key">
                <template #header>
                    <i class="pi pi-pencil mr-2"></i>
                    <span class="mr-3">{{ editor.name }}</span>
                    <!--<a href="test"><i class="pi pi-times-circle mr-2"></i></a>-->
                </template>
                <BlogEditor :redis_key="editor.redis_key" @editor-callback="editor_callback" />
            </TabPanel>
        </TabView>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="newBlogDialog" :style="{ width: '800px' }" :header="text.head" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="new_blog_title" v-model="new_blog_title" class="w-full" />
                    <label for="new_blog_title">Titel</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="new_blog_category" v-model="new_blog_category" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto" />
                    <label for="new_blog_category">Kategorie</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button @click="create_blog()" :label="text.new" class="p-button-success" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch, inject} from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import BlogEditor from './submodules/CaseStudies-Data-Editor.vue'
import { useAuthStore } from '@/core/store/AuthStore';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { slugify, swiss_date, swiss_date_time, getRandomId, preview_dataset } from '@/core/var/tools'
import Simplestore from '@/core/var/Simplestore';
import ImagePlaceholder from '@/core/components/UI/Main-Image-Placeholder.vue';
const channel = ref(useCustomerStore().getCustomer.customer);


const SERVICE_URL = inject('SERVICE_URL')
const MODULE = 'casestudies';

const blogs = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const changes = ref(0)
const save_text = ref("Speichern")
const blog_status = ref([])
const editing = ref([])
const users = ref([])
const new_blog_title = ref("")
const new_blog_category = ref("")
const auth = useAuthStore();
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const filter_blogs = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
const filter_blog_category = ref()
const filter_blog_status = ref()
const newBlogDialog = ref(false)
const activeTabIndex = ref(0)



const text = {
    head: 'Case Studies',
    speichern: "Speichern",
    new: 'Neue Casestudie',
    eintrag: 'Der Eintrag muss zuerst gespeichert werden!',
    created: 'Casestudie wurde erstellt',
    success: 'Neue Casestudie wurde erfolgreich erstellt',
    delmsg: 'Bist du sicher, dass du den Datensatz löschen möchtest?',
    copied: "Magazin-Artikel wurde kopiert"
}


const endpoints = {
    get: "casestudies/get",
    getsystem: 'get-system',
    getcategories: 'casestudies/categories',
    gettags: 'casestudies/tags',
    getusers: 'get-users',
    save: 'casestudies/save'
}



onMounted(async () => {
    loader.value = true;
    await bootstrap().then(() => {
        loader.value = false;
    });
})

watch(loader, res => {
    Simplestore.spinner = res;
});

const bootstrap = async () => {
    try {
        const [blogsResponse, systemResponse, usersResponse, categoriesRessponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsystem}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getusers}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getcategories}`),
        ]);
        blogs.value = blogsResponse.data;
        blog_categories.value = categoriesRessponse.data;
        blog_tags.value = systemResponse.data.blog_tags;
        blog_status.value = systemResponse.data.blog_status;
        users.value = usersResponse.data;
    } catch (error) {
        console.error(error);
    }
};

const onTextFieldRowReorder = (e) => {
    blogs.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const onBlogRowEdit = (e) => {
    if (blogs.value[e.index]["redis_key"] != "") {
        if (blogs.value[e.index][e.field] != e.newValue) {
            console.log("Blog " + blogs.value[e.index].redis_key + " changed: " + e.field + " from " + blogs.value[e.index][e.field] + " to " + e.newValue)
            blogs.value[e.index][e.field] = e.newValue
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'success', summary: 'Änderungen gespeichert', detail: 'Die Änderungen wurden erfolgreich gespeichert', life: 3000 })
        }
    }
    else {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Der Magazin Eintrag muss zuerst gespeichert werden!', life: 3000 })
    }
}

const reset_changes = () => {

    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`)
                .then(response => {
                    blogs.value = response.data
                    changes.value = 0
                    save_text.value = "Speichern"
                })
        }
    })
}

const save_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen speichern?',
        header: 'Änderungen speichern',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            loader.value = true;
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + "/"+endpoints.save, blogs.value)
                .then(response => {
                    blogs.value = response.data
                    toast.add({ severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000 })
                    changes.value = 0
                    save_text.value = "Speichern"
                    loader.value = false
                })
        }
    })
}


const create_blog = () => {
    if (!new_blog_title.value) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Titel ein', life: 3000 })
        return
    }
    if (!new_blog_category.value) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte wählen Sie eine Kategorie aus', life: 3000 })
        return
    }
    if (check_if_slug_exists(new_blog_title.value)) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Ein Artikel mit diesem Titel existiert bereits', life: 3000 })
        return
    }

    const slug = slugify(new_blog_title.value);



    let new_blog = {
        "workflow_id": "",
        "title": new_blog_title.value,
        "teaser": "",
        "slug": slug,
        "redis_key": setRedisKey(slug,MODULE),
        "category": [new_blog_category.value],
        "status": "draft",
        "status_props": {
            "key": "published",
            "value": "Publiziert"
        },
        "presence": "",
        "user": auth.userdata.user_email,
        "header_image": "",
        "header_image_preview": "",
        "header_image_newsletter": "",
        "author": "",
        "dc": Date.now(),
        "dm": Date.now(),
        "publish_date": swiss_date(Date.now()),
        "runtime_from": "",
        "runtime_to": "",
        "tags": [],
        "matching_products_neuraxis": [],
        "matching_products_title": "",
        "events": [],
        "event_type": "",
        "sage_groups": [],
        "changes": [
            {
                "user": auth.userdata.user_email,
                "date": Date.now(),
                "message": "Magazin-Artikel wurde erstellt"
            }
        ],
        "content": [],
        "text_content": "",
        "image_library": [],
        "preview":{}
    }
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    blogs.value.unshift(new_blog)
    toast.add({ severity: 'success', summary: text.new, detail: text.success, life: 3000 })
    newBlogDialog.value = false
}

const setRedisKey = (slug, mdle) => {
    return `${channel.value}:${mdle}:${slug}`
}



const delete_blog = (redis_key) => {
    confirm.require({
        message: text.delmsg,
        header: 'Artikel löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Artikel löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in blogs.value) {
                if (blogs.value[key].redis_key == redis_key) {
                    blogs.value.splice(key, 1)
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000 })
                }
            }
        }
    })
}

const edit_blog = (redis_key) => {
    for (let key in blogs.value) {
        if (blogs.value[key].redis_key == redis_key) {
            let found = editing.value.some((el) => el.redis_key === redis_key);
            if (!found) {
                let name = ""
                if (blogs.value[key].title.length > 25) {
                    name = blogs.value[key].title.substring(0, 25) + "..."
                } else {
                    name = blogs.value[key].title
                }
                editing.value.push({
                    "redis_key": blogs.value[key].redis_key,
                    "name": name
                })
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const copy_blog = (redis_key) => {
    for (let key in blogs.value) {
        if (blogs.value[key].redis_key == redis_key) {
  
            let new_blog = JSON.parse(JSON.stringify(blogs.value[key]))
            new_blog.workflow_id = getRandomId(6)
            new_blog.slug = new_blog.slug + "-copy"
            new_blog.title = new_blog.title + " (Kopie)"
            new_blog.redis_key = setRedisKey(new_blog.slug, MODULE)
            new_blog.dc = Date.now()
            new_blog.dm = Date.now()
            new_blog.publish_date = swiss_date(Date.now())
            new_blog.user = auth.userdata.user_email
            new_blog.image_library = []
            new_blog.content = []
            new_blog.changes = [
                {
                    "user": auth.userdata.user_email,
                    "date": swiss_date(Date.now()),
                    "message": text.copied
                }
            ]
            new_blog.status = "draft"
            blogs.value.unshift(new_blog)
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'success', summary: 'Artikel kopiert', detail: 'Der Artikel wurde erfolgreich kopiert', life: 3000 })
            // console.log(new_blog)
            // console.log(blogs.value);
            break

        }
    }
}

const check_if_slug_exists = (title) => {
    let exists = false
    let slug = slugify(title)
    blogs.value.forEach(blog => {
        if (blog.slug == slug) {
            exists = true
        }
    })
    return exists
}

const editor_callback = (e) => {
    if (e.action == "close_tab") {
        for (let key in editing.value) {
            if (editing.value[key]["redis_key"] == e.redis_key) {
                editing.value.splice(key, 1)
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const select_status_filter = () => {
    filter_blogs.value['global'].value = filter_blog_status.value
}

const select_category_filter = () => {
    filter_blogs.value['global'].value = filter_blog_category.value
}

watch([activeTabIndex], () => {
    if (activeTabIndex.value == 0) {
        bootstrap()
    }
})

//////////////////////////
// Formatting Functions //
//////////////////////////


const get_category = (category) => {
    for (let i = 0; i < blog_categories.value.length; i++) {
        if (blog_categories.value[i].value == category) {
            return blog_categories.value[i].name
        }
    }
}

const get_status = (status) => {
    for (let i = 0; i < blog_status.value.length; i++) {
        if (blog_status.value[i].value == status) {
            if (status == "published") {
                return "Publiziert"
            }
            if (status == "draft") {
                return "Entwurf"
            }
        }
    }
}
</script>

<style>
.spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>

<style lang="scss" scoped>
@import '@/core/assets/primevue/primeflex.scss';
</style> 