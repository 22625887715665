<template>
    <div class="flex my-3">
        <LoadingSpinner size="6" v-if="!props.logo" />
        <div class="self-center">
            <img :src="getImgById(selectedLogo ? selectedLogo.id : null)" width="50" />
        </div>
        <div class="self-center">
            {{ selectedLogo ? selectedLogo.name : '' }}
        </div>
    </div>
    <Dropdown v-model="selectedLogo" :options="Logos" optionLabel="id" placeholder="Snappy Logo auswählen" class="w-full md:w-14rem">
        <template #value="slotProps">
            <div class="flex align-items-center" v-if="slotProps.value && slotProps.value.name">
                <div>{{ slotProps.value.name }}</div>
            </div>
            <div v-else>
                {{ slotProps.placeholder }}
            </div>
        </template>

        <template #option="slotProps">
            <div class="flex align-items-center">
                <img :src="slotProps.option.img" width="50" height="50" class="align-middle" />
                <span class="mt-3 ml-2">{{ slotProps.option.name }}</span>
            </div>
        </template>
    </Dropdown>
</template>

<script setup>
import { ref, defineEmits, watch, defineProps, computed } from 'vue';
import snappydata from '../../assets/images/snappy_data.svg'
import snappyelements from '../../assets/images/snappy_elements.svg'
import snappyslows from '../../assets/images/snappy_flows.svg'
import snappypages from '../../assets/images/snappy_pages.svg'
import snappyecommerce from '../../assets/images/snappy_ecommerce.svg'
import snappybexio from '../../assets/images/snappy_bexio.svg'
import snappyinvoice from '../../assets/images/snappy_invoice_automation.svg'
import snappyllm from '../../assets/images/snappy_llm.svg'
import snappyfintech from '../../assets/images/snappy_fintech.svg'
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';

const emits = defineEmits(['callback'])
const props = defineProps(['logo', 'test'])

let selectedLogo = ref(null);

const logoProp = computed(() => props.logo);

const Logos = [
    { id: 'snappy_flows', img: snappyslows, name: 'Snappy Flows' },
    { id: 'snappy_data', img: snappydata, name: 'Snappy Data' },
    { id: 'snappy_elements', img: snappyelements, name: 'Snappy Elements' },
    { id: 'snappy_pages', img: snappypages, name: 'Snappy Pages' },
    { id: 'snappy_ecommerce', img: snappyecommerce, name: 'Snappy Ecommerce' },
    { id: 'snappy_bexio', img: snappybexio, name: 'Snappy Bexio' },
    { id: 'snappy_invoice', img: snappyinvoice, name: 'Snappy Invoice Automation' },
    { id: 'snappy_llm', img: snappyllm, name: 'Snappy Artificial Intelligence' },
    { id: 'snappy_fintech', img: snappyfintech, name: 'Snappy Fintech' },
]

watch(logoProp, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        const matchingLogo = Logos.find(logo => logo.id === newVal);
        if (matchingLogo) {
            selectedLogo.value = { ...matchingLogo };
        } else {
            selectedLogo.value = null;
        }
    }
}, { immediate: true });


watch(() => selectedLogo.value, (newVal) => {
    if (newVal) {
        emits('callback', newVal);
    }
}, { immediate: true })



const getImgById = (id) => {
    const logo = Logos.find(logo => logo.id === id);
    return logo ? logo.img : null;
}
</script>
