import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon,
} from '@heroicons/vue/24/outline';

export default [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
    { name: 'Stammdaten', href: '/dashboard/Stammdaten', icon: CogIcon, current: false, order: 1 },
    { name: 'Blog', href: '/dashboard/Blog', icon: DocumentIcon, current: false, order: 2 },
    { name: 'Berater', href: '/dashboard/Berater', icon: UserGroupIcon, current: false, order: 3 },
    { name: 'Referenzen', href: '/dashboard/Referenzen', icon: MegaphoneIcon, current: false, order: 4 },
    { name: 'System', href: '/dashboard/System', icon: CogIcon, current: false, order: 5 }
]
;