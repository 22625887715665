
<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, inject } from 'vue'
import { useToast } from 'primevue/usetoast'
import { EnvelopeIcon, ArchiveBoxIcon, CloudIcon } from '@heroicons/vue/20/solid';
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
//import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import StandardText from '@/core/components/Components/Builder-Blocks/Standard-Text.vue'
import StandardImage from '@/core/components/Components/Builder-Blocks/Standard-Image.vue'
import StandardTitle from '@/core/components/Components/Builder-Blocks/Standard-Title.vue'
import CTAButton from '@/core/components/Components/Builder-Blocks/Cta-Button.vue'
import HtmlBlock from '@/core/components/Components/Builder-Blocks/Html-Block.vue'
import QuoteBlock from '@/core/components/Components/Builder-Blocks/Quote-Block.vue'
import VideoURL from '@/core/components/Components/Builder-Blocks/Video-URL.vue'
import ImageTextBlock from '@/core/components/Components/Builder-Blocks/Image-Text.vue'
import ComparsionBlock from '@/core/components/Components/Builder-Blocks/Comparsion-Block.vue'
import QuotingLogoBlock from '@/core/components/Components/Builder-Blocks/Quoting-Logo-Block.vue'
import FAQBlock from '@/core/components/Components/Builder-Blocks/FAQ-Block.vue'
import Simplestore from '@/core/var/Simplestore';
import { swiss_date_time, preview_dataset } from '@/core/var/tools'
import { uploadSingleImage } from '@/core/var/crud'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import SnappyLogosDropdown from '../components/Snappy-Logos-Dropdown.vue'
import SnappyLogosMultiselect from '../components/Snappy-Logos-Multiselect.vue'
import ImageEditor from '@/core/components/Components/Builder-Blocks/Components/Image-Editor.vue'


const content_blocks = ref(['standard_text',
    'standard_title',
    'standard_image',
    'cta_button',
    'html_block',
    'quote_block',
    'image_text',
    'comparsion_block',
    'quoting_logo',
    'video_url', 'faq_block'])

const channel = ref(useCustomerStore().getCustomer.customer);

const SERVICE_URL = inject('SERVICE_URL')
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)

const props = defineProps({
    redis_key: { type: String }
})

const new_blog_categories = ref();
const workflow_id = ref("")
const blog = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const blog_sort = ref([])
const blog_status = ref([])
const blog_snappy_icon = ref();
const blog_snappy_logos = ref();
const blog_icon = ref(null);
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null);
const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_button_loading = ref(false);
const blog_text_vorschlag_dialog = ref(false);
const disabled = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])
const title_image_cropper_preview = ref()

const MODULE = 'products';

const endpoints = {
    get: "products/get",
    getsingle: "products/get-product",
    savesingle: "/products/save-single",
    getsystem: 'get-system',
    getcategories: 'products/categories/get',
    gettags: 'products/tags',
    getusers: 'get-users',
    save: 'products/save',
    uploadsingle: 'products/upload-image',
    uploadlibrary: 'products/upload-library-image',
    preview: `${MODULE}/preview/save`
}

const getSnappyLogo = res => {
    blog_snappy_icon.value = res.id;
}
const getSnappyLogos = res => {
    //console.log(res)
    blog_snappy_logos.value = res;
}

onMounted(() => {
    loader.value = true;
    getBlogs().then(() => {
        loader.value = false;
    });
    fetchSystemAndUsers();
})

watch(loader, res => {
    Simplestore.spinner = res;
});



const fetchSystemAndUsers = async () => {
    try {
        const systemRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsystem}`);
        const usersRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getusers}`);
        const categoriesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getcategories}`);

        const [systemResponse, usersResponse, categoriesResponse] = await Promise.all([systemRequest, usersRequest, categoriesRequest]);

        blog_categories.value = categoriesResponse.data;
        blog_tags.value = systemResponse.data.blog_tags;
        blog_sort.value = systemResponse.data.blog_sort;
        blog_status.value = systemResponse.data.blog_status;

        users.value = usersResponse.data;
    } catch (error) {
        console.error("Error fetching system and users data:", error);
    }
};

const preview = () => {
    const workload = {
        redis_key: blog.value.redis_key,
        content: blog.value.content,
        title: blog.value.title,
        company: blog.value.company,
        header_image: blog.value.header_image,
    };

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.preview}`, workload)
        .then(response => {
            preview_dataset(blog.value.slug, SERVICE_URL, MODULE)
        })
}

const getBlogs = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`, payload);
        const { data } = response;

        blog.value = data;
        blog_icon.value = data.logo == null ? 'snappy_data' : data.logo;
        new_blog_categories.value = data.category;
        blog.value.category = data.category[0];
        workflow_id.value = data.workflow_id;
        blog_content.value = data.content;
        blog_title.value = data.title;
        blog_changes.value = data.changes;
        image_library.value = data.image_library;
        GPT_Primer(data.text_content);

    } catch (error) {
        console.error("Error fetching blog data:", error);
    }
};

const GPT_Primer = (blog_content) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle eine kurze Zusammefassung mit maximal 30 Wörtern von diesem Text (in deutsch): ${blog_content}`,
            button_name: "Zusammefassung erstellen",
        }
    ];
}


const close_tab = () => {
    confirm.require({
        message: 'Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

const showGallery = (index) => {
    activeImageIndex.value = index
    displayGallery.value = true
}

const update_template_data = (e) => {
    if (e.action == "save") {
        blog_content.value[e.index] = e.template_data
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000 })
    } else if (e.action == "delete") {
        blog_content.value.splice(e.index, 1)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000 })
    } else if (e.action == "add") {
        blog_content.value.push(e.template_data)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000 })
    }
}

const save_changes = () => {

    if (blog.value.company === '' || blog.value.company === undefined) {
        toast.add({
            severity: "error",
            summary: "Fehler",
            detail: "Bitte Untertitel angeben",
            life: 3000,
        });
    } else {
        loader.value = true;
        blog.value["content"] = blog_content.value
        blog.value["changes"] = blog_changes.value
        blog.value["image_library"] = image_library.value
        //blog.value["category"] = blog.value.category;
        blog.value["logo"] = blog_snappy_icon.value;
        //blog.value["category_logo"] = blog_snappy_logos;
        blog.value["category"] = new_blog_categories.value;

        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + endpoints.savesingle, blog.value)
            .then(response => {
                toast.add({ severity: 'success', summary: blog.value.title + ' gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000 })
                loader.value = false;
                // tmp hack
                blog.value["category"] = blog.value.category[0];
            })
    }
}

const reset_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = { "redis_key": props.redis_key }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + "/" + endpoints.getsingle, payload)
                .then(response => {
                    blog.value = response.data
                })
        }
    })
}

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        blog_content.value = e.value
    }
}


const crop_header_image = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image = res.data.image_url;
        edit_header_image.value = false;
    });
}


const crop_header_image_preview = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image_preview = res.data.image_url
        edit_header_image_preview.value = false
    });
}


const libraryUploadGetData = (res) => {
    blog.value['image_library'].push({ image: res });
    console.log(image_library.value);
}

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}


const update_text_content = res => {

    const teaser = blog.value.text_teaser != undefined ? blog.value.text_teaser : '';
    blog.value.text_teaser = teaser + res.chatGptAnswers;
    chatGptDialog.value = false;
}

const change_image = res => {
    crop_header_image(res, 'header-image');
    editTitleImageDialog.value = false;
}

const change_preview_image = res => {
    crop_header_image_preview(res, 'header_image_preview');
    editTitleImagePreviewDialog.value = false;
}


</script>

<style>
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
</style>

<style lang="scss" scoped>
//@import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <!-- <LoadingSpinner v-if="loader" size="5" color="fill-primary-600" classs="m-5" /> -->
    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />
    <Toolbar>

        <template #start>
            <Button v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
            <Button @click="save_changes()" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" />
            <Divider layout="vertical" />
            <Button v-tooltip.bottom="'Preview Artikel'" @click="preview" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" />
            <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab()" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" />
        </template>
        <template #end>
            <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" />
        </template>
    </Toolbar>
    <TabView class="mt-2" ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>

            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-2/3 m-2">
                    <div class="my-2">
                        <Editor editorStyle="width: 100%; min-height: 350px;" v-model="blog.text_teaser">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                    <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                    <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                    <!-- <button v-tooltip.bottom="'chatGPT'" @click="chatGptDialog = true">
                                        <CloudIcon class="h-10 w-10" />
                                    </button> -->
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <div class="my-2">
                        <Panel header="Call to Action">
                            <div class="my-8">
                                <span class="p-float-label">
                                    <InputText id="cta_title" :autoResize="true" v-model="blog.cta_title" class="w-full" />
                                    <label for="cta_title">CTA Titel</label>
                                </span>
                            </div>
                            <div class="my-8">
                                <span class="p-float-label">
                                    <InputText id="cta_subtitle" :autoResize="true" v-model="blog.cta_subtitle" class="w-full" />
                                    <label for="cta_subtitle">CTA SubTitel</label>
                                </span>
                            </div>
                            <div class="my-8">
                                <!-- <label for="cta_teaser">CTA Teaser</label> -->
                                <span class="p-float-label">
                                    <Editor editorStyle="width: 100%; min-height: 350px;" v-model="blog.cta_teaser" placeholder="CTA Teaser">
                                        <template v-slot:toolbar>
                                            <span class="ql-formats">
                                                <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                                <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                                <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                                <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                                <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                                <!-- <button v-tooltip.bottom="'chatGPT'" @click="chatGptDialog = true">
                                        <CloudIcon class="h-10 w-10" />
                                    </button> -->
                                            </span>
                                        </template>
                                    </Editor>

                                </span>
                            </div>
                        </Panel>
                    </div>

                </div>

                <div class="w-auto md:w-1/3 m-2">
                    <div class="">
                        <Accordion class="accordion-custom mt-3" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 text-xl">Metadaten</span>
                                </template>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Textarea id="title" :autoResize="true" v-model="blog.title" class="w-full" />
                                        <label for="title">Titel</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="company" :autoResize="true" v-model="blog.company" class="w-full" />
                                        <label for="company">Untertitel</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <SnappyLogosDropdown @callback="getSnappyLogo" :logo="blog_icon" />
                                        <label for="snappy_logo">Logo</label>
                                    </span>
                                </div>
                                <!-- <div class="my-10">
                                    <span class="p-float-label">
                                        <SnappyLogosMultiselect @callback="getSnappyLogos" :logo="blog.category_logo" id="snappy_category_logo" placeholder="Kategorien" />
                                        <label for="snappy_category_logo">Kategorien</label>
                                    </span>
                                </div> -->
                                <div class="my-10">
                                    <span class="p-float-label">
                                        <MultiSelect id="blog_categories" v-model="new_blog_categories" :options="blog_categories" optionLabel="name" optionValue="value" class="w-full" filter />
                                        <label for="blog_categories">Kategorie</label>
                                    </span>
                                </div>

                                <div class="my-7">
                                    <img v-if="blog.header_image" :src="blog.header_image" class="w-1/2 my-2" />
                                    <Button @click="editTitleImageDialog = true" v-tooltip.bottom="'Titel-Bild editieren'" label="Titel-Bild" class="w-full mr-2 my-1 p-button" icon="pi pi-image" />
                                    <img v-if="blog.header_image_preview" :src="blog.header_image_preview" class="w-1/2 my-2" />
                                    <Button @click="editTitleImagePreviewDialog = true" v-tooltip.bottom="'Vorschau-Bild editieren'" label="Vorschau-Bild" class="w-full p-button my-1" icon="pi pi-image" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="field col-12 mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2" style="font-size: 130%;">Bilder Bibliotek</span>
                                    <Avatar :label="String(image_library.length)" class="mr-2" shape="circle" />
                                </template>

                                <Galleria :value="image_library" v-model:activeIndex="activeImageIndex" :numVisible="7" containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false" v-model:visible="displayGallery">
                                    <template #item="slotProps">

                                        <img :src="slotProps.item.image" style="width: 100%; display: block;" />
                                    </template>
                                </Galleria>

                                <div v-if="image_library" class="grid">
                                    <div v-for="(image, index) of image_library" class="flex" :key="index">
                                        <img :src="image.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" @click="showGallery(index)" />
                                    </div>
                                </div>
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                    <!-- <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder aus der Bibliotek wählen'" label="Bibliotek" class="mr-2 p-button-success w-auto" icon="pi pi-folder" />  -->
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="field col-12 mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2" style="font-size: 130%;">Änderungsverlauf</span>
                                    <Avatar :label="String(blog_changes.length)" class="mr-2" shape="circle" />
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes" :key="index">
                                        <Card style="width: 100%" class="mt-3 shadow-3">
                                            <template #content>
                                                <div class="flex">
                                                    <Chip :label="change.user" icon="pi pi-user" class="mr-3" />
                                                    <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" />
                                                </div>
                                                <div class="flex">
                                                    <span class="m-2 p-2 w-full" style="background-color: #b3e5fc; color: #23547b; border-radius: 3px;">{{ change.message }}</span>
                                                </div>
                                            </template>
                                        </Card>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-box mr-2"></i>
                <span class="mr-3">Builder</span>
            </template>
            <div class="md:flex flex-row my-5">
                <div class="w-auto md:w-2/3 m-2 border-solid border border-gray-200 ">

                    <DataTable :value="blog_content" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
                        <Column :rowReorder="true" headerStyle="width: auto" :reorderableColumn="false" />
                        <Column field="template">
                            <template #header>
                                <i class="pi pi-box mr-2"></i>
                                <span class="mr-3">Blocks</span>
                                <Avatar :label="String(blog_content.length)" shape="circle" class="mr-2 bg-primary-500 text-white" style="" />
                            </template>
                            <template #body="slotProps">

                                <StandardText v-if="slotProps.data.template == 'standard_text'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />

                                <ImageTextBlock v-if="slotProps.data.template == 'image_text'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />

                                <QuotingLogoBlock v-if="slotProps.data.template == 'quoting_logo'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />

                                <ComparsionBlock v-if="slotProps.data.template == 'comparsion_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :workflow_id="workflow_id" />


                                <StandardTitle v-if="slotProps.data.template == 'standard_title'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />

                                <StandardImage v-if="slotProps.data.template == 'standard_image'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />

                                <CTAButton v-if="slotProps.data.template == 'cta_button'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />

                                <HtmlBlock v-if="slotProps.data.template == 'html_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />

                                <QuoteBlock v-if="slotProps.data.template == 'quote_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :workflow_id="workflow_id" />

                                <VideoURL v-if="slotProps.data.template == 'video_url'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :workflow_id="workflow_id" />

                                <FAQBlock v-if="slotProps.data.template == 'faq_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :workflow_id="workflow_id" :image_library="image_library" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="w-auto md:w-1/3 mx-5 border-solid border border-gray-200 p-4">
                    <div>
                        <div v-for="key in content_blocks" :key="key">
                            <StandardText v-if="key == 'standard_text'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />

                            <ImageTextBlock v-if="key == 'image_text'" :image_library="image_library" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" />

                            <QuotingLogoBlock v-if="key == 'quoting_logo'" :image_library="image_library" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" />

                            <ComparsionBlock v-if="key == 'comparsion_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" />

                            <StandardTitle v-if="key == 'standard_title'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />

                            <StandardImage v-if="key == 'standard_image'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                            <CTAButton v-if="key == 'cta_button'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <HtmlBlock v-if="key == 'html_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <QuoteBlock v-if="key == 'quote_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" />
                            <VideoURL v-if="key == 'video_url'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" />
                            <FAQBlock v-if="key == 'faq_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" :image_library="image_library" />
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <ImageEditor :edit="edit_header_image" :image_library="image_library" :image="blog.header_image" @update="change_image" />
    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <ImageEditor :edit="edit_header_image_preview" :image_library="image_library" :image="blog.header_image_preview" @update="change_preview_image" />
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>


