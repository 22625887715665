<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import ModalButtons from './Components/Modal-Menue.vue'
// local variables
const editDialog = ref(false)
const BLOCK_NAME = "standard_text";

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    workflow_id: { type: String, required: false },
    image_library: { type: Object, required: false },
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const html_content = ref(props.data.html_content)
const template = ref(BLOCK_NAME)

// functions
const edit_block = () => {
    editDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "html_content": html_content.value,
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "html_content": "",
            },
            "action": "add",
            "index": props.index
        }
    )
}
const toggleDialog = () => {
    editDialog.value = false
}
</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-align-left " name="Standard Text" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" name="Standard Text">
        <Divider />
        <span v-html="html_content" class="builder_blocks_html_content text-sm py-3"></span>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="">
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>
            <div class="my-3">
                <Editor editorStyle="width: 100%; min-height: 550px;" v-model="html_content">
                </Editor>
            </div>
        </div>
    </Dialog>
</template>
<style>
.builder_blocks_html_content h2{
    font-size: 1.5rem;
}
.builder_blocks_html_content a{
    text-decoration: underline;
    color: blue;
}
</style>