<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'
import { useCustomerStore } from '@/core/store/CustomerStore';
import '@/core/assets/primevue/neuraxis.css';


const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const token = ref(false)
const user_email = ref("")
const user_firstname = ref("")
const user_lastname = ref("")
const user_password = ref("")
const user_password_repeat = ref("")
const password_too_weak = ref(false)
const slug = ref(useCustomerStore().getCustomer.customer);
const channel = ref(slug)

const keep_logged_in = ref(true);
const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;


onMounted(() => {
    const tkn = getQueryParam('token');
    token.value = tkn;
    if (token.value) {
        axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/invite-token`, { token: token.value })
            .then(response => {
                if (response.data.status == 'success') {
                    user_email.value = response.data.user_email
                }
            })
    }
})

const register = () => {
    if (user_password.value != user_password_repeat.value || user_password.value.length <= 8 || user_password.value.match(/[a-z]/g) == null || user_password.value.match(/[A-Z]/g) == null || user_password.value.match(/[0-9]/g) == null || user_password.value.match(/[^a-zA-Z\d]/g) == null) {
        password_too_weak.value = true;
        return;
    }
    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/register`, { token: token.value, user_email: user_email.value, user_firstname: user_firstname.value, user_lastname: user_lastname.value, user_password: user_password.value })
        .then(response => {
            if (response.data.status == 'success') {
                setTimeout(() => {
                    window.location.href = '/'
                }, 3000)
            }
        })
}
const getLogo = () => {
    return require(`../assets/${appmode.assets.logo}`);
}
</script>

<template>
    <div class="flex justify-center mt-6 w-full">
        <div class="bg-white p-4 rounded shadow-lg w-full lg:w-4/5">
            <div class="text-center mb-5">
                <img class="mx-auto h-12 w-auto animate-shake" :src="getLogo()" alt="" />
                <div class="text-gray-900 text-3xl font-medium mb-3">Snappy</div>
                <span v-if="user_email" class="text-gray-600 font-medium leading-relaxed">You have been invited to the <span class="text-blue-500">Snappy</span> Snappy instance!</span>
                <span v-if="!user_email" class="font-medium leading-relaxed text-red-500">This invitation link has expired or is no longer valid!</span>
            </div>

            <div v-if="user_email">
                <label for="email" class="block text-gray-900 font-medium mt-2">Email</label>
                <InputText disabled id="email" v-model="user_email" type="text" class="w-full mt-3" />

                <label for="firstname" class="block text-gray-900 font-medium mt-2">First Name</label>
                <InputText id="firstname" v-model="user_firstname" type="text" class="w-full mt-3" />

                <label for="lastname" class="block text-gray-900 font-medium mt-2">Last Name</label>
                <InputText id="lastname" v-model="user_lastname" type="text" class="w-full mt-3" />

                <label for="password" class="block text-gray-900 font-medium mt-2">Password</label>
                <InputText id="password" v-model="user_password" type="password" class="w-full mt-3" />
                <small v-if="password_too_weak" class="text-red-500">Password too weak</small>

                <label for="password_repeat" class="block text-gray-900 font-medium mt-2">Repeat Password</label>
                <InputText id="password_repeat" v-model="user_password_repeat" type="password" class="w-full mt-3" />

                <Button :disabled="user_email && user_firstname && user_lastname && user_password && user_password_repeat && user_password == user_password_repeat ? false : true" @click="register()" label="Register" icon="pi pi-check" class="w-full mt-4"></Button>
            </div>
        </div>
    </div>

</template>